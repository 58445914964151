import React, { useContext, useEffect, useState } from "react";
import { useScreenTransition } from "../../Hooks/useScreenTransition";
import { ReactComponent as USER } from "../../assets/icons/User.svg";
import { ChevronDown, Menu, ArrowRight, X } from "react-feather";
import { AuthContext } from "../../Context/AuthContext";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { DataContext } from "../../Context/DataContext";
import { AccountType } from "../../utility/enums";
import { useHistory } from "react-router-dom";
import { NotificationContext } from "../../Context/NotificationContext";

import "./LandingPage.css";
import "./NewNavbar.css";

const NewNavbar = ({ windowSize, isCreateAccountOpen, isLoginFormOpen }) => {
  const { transitionTo } = useScreenTransition();
  const [openMobile, setOpenMobile] = useState(false);

  const { showNotification } = useContext(NotificationContext);
  const { signedIn, clearSignOut } = useContext(AuthContext);
  const { userData } = useContext(DataContext);

  const history = useHistory();

  const navItems = [
    {
      title: "Contact",
      path: "/contact",
    },
    {
      title: "Review",
      path: "/reviews",
    },
    {
      title: "Free Quote",
      path: "/quote/details",
    },
  ];

  useEffect(() => {
    if (windowSize && windowSize.width > 768) {
      setOpenMobile(false);
    }
  }, [windowSize]);

  const changeLoginOpen = () => {
    isLoginFormOpen(true);
    setOpenMobile(false);
  };

  const changeCreateAccountOpen = () => {
    isLoginFormOpen(false);
    setOpenMobile(false);
    isCreateAccountOpen(true);
  };

  const signOut = () => {
    setOpenMobile(false);
    history.push("/");

    firebase
      .auth()
      .signOut()
      .then(() => {
        showNotification("Success", "You have signed out.", "success");
        clearSignOut();
      })
      .catch(() => {
        showNotification(
          "Error",
          "There was an error signing out. Please, try again.",
          "error"
        );
      });
  };

  return (
    <div className="container nweNavbarContainer">
      <div>
        <img
          src={require("../../assets/logo.png")}
          alt="Logo eVerbary"
          className="logo"
          onClick={(e) => transitionTo("/", e)}
        />
      </div>
      <div className="menuItems">
        {navItems.map((item, index) => (
          <div key={index} className="relative group">
            <div
              className="hover:text-[#12ABBF]  hover:cursor-pointer menuText"
              onClick={(e) => transitionTo(item.path, e)}
            >
              {item.title}
            </div>
            <div className="underline"></div>
          </div>
        ))}
      </div>
      <div className="navButton">
        {signedIn ? (
          <div className="menu-container">
            <button className="menu-button">
              <USER className="user-icon" />
            </button>
            <div className="submenu-container">
              <div className="submenu-content">
                {userData &&
                  (userData.role === AccountType.OWNER ||
                    userData.role === AccountType.SUPPORT ||
                    userData.role === AccountType.TRANSLATER) && (
                    <div
                      className="submenu-link-container"
                      onClick={(e) => transitionTo("/office/translations", e)}
                    >
                      <p className="submenu-link">
                        {userData.role === AccountType.OWNER
                          ? "Owner Portal"
                          : userData.role === AccountType.SUPPORT
                          ? "Support Portal"
                          : "Translator Portal"}
                      </p>
                    </div>
                  )}
                {userData &&
                  (userData.role === AccountType.OWNER ||
                    userData.role === AccountType.SUPPORT ||
                    userData.role === AccountType.TRANSLATER) && (
                    <>
                      <div
                        className="submenu-link-container"
                        onClick={(e) => transitionTo("/account/profile", e)}
                      >
                        <p className="submenu-link">Account Settings</p>
                      </div>
                    </>
                  )}
                {userData && userData.role === AccountType.CLIENT && (
                  <>
                    <div
                      className="submenu-link-container"
                      onClick={(e) => transitionTo("/account/profile", e)}
                    >
                      <p className="submenu-link">Profile</p>
                    </div>
                    <div
                      className="submenu-link-container"
                      onClick={(e) => transitionTo("/account/shipping", e)}
                    >
                      <p className="submenu-link">Shipping</p>
                    </div>
                    <div
                      className="submenu-link-container"
                      onClick={(e) => transitionTo("/account/quotes", e)}
                    >
                      <p className="submenu-link">Quotes</p>
                    </div>
                    <div
                      className="submenu-link-container"
                      onClick={(e) => transitionTo("/account/orders", e)}
                    >
                      <p className="submenu-link">Orders</p>
                    </div>
                  </>
                )}
                <div
                  className="submenu-link-container"
                  onClick={() => signOut()}
                >
                  <p className="submenu-link">Sign Out</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <button className="login-button" onClick={(e) => changeLoginOpen()}>
            log - in
          </button>
        )}

        <hr className=" silder" />
        <button
          className="blue-button"
          onClick={(e) =>
            transitionTo(
              `${
                signedIn && firebase.auth().currentUser
                  ? "/order/documents"
                  : "/order/contact"
              }`,
              e
            )
          }
        >
          Order
        </button>
      </div>
      <div className="mobile-menu-container">
        <div onClick={() => setOpenMobile(!openMobile)}>
          {openMobile ? <X className="icon" /> : <Menu className="icon" />}
        </div>
      </div>

      {openMobile && (
        <div className="mobile-menu">
          <div className="menu-header">
            <h2 className="menu-title">Menu</h2>
            <div onClick={() => setOpenMobile(false)}>
              <X className="icon" />
            </div>
          </div>
          <hr className="divider" />

          <div className="menu-items">
            {navItems.map((item, index) => (
              <div
                key={index}
                className="menu-item"
                onClick={(e) => {
                  setOpenMobile(false);
                  transitionTo(item.path, e);
                }}
              >
                {item.title}
              </div>
            ))}
          </div>
          <div className="menu-actions">
            {signedIn ? (
              <>
                {/* <button className="user-button">
                  <USER className="user-icon" />
                </button> */}
                <div className="submenu-content">
                  {userData &&
                    (userData.role === AccountType.OWNER ||
                      userData.role === AccountType.SUPPORT ||
                      userData.role === AccountType.TRANSLATER) && (
                      <div
                        className="submenu-link-container"
                        onClick={(e) => transitionTo("/office/translations", e)}
                      >
                        <p className="submenu-link">
                          {userData.role === AccountType.OWNER
                            ? "Owner Portal"
                            : userData.role === AccountType.SUPPORT
                            ? "Support Portal"
                            : "Translator Portal"}
                        </p>
                      </div>
                    )}
                  {userData &&
                    (userData.role === AccountType.OWNER ||
                      userData.role === AccountType.SUPPORT ||
                      userData.role === AccountType.TRANSLATER) && (
                      <div
                        className="submenu-link-container"
                        onClick={(e) => transitionTo("/account/profile", e)}
                      >
                        <p className="submenu-link">Account Settings</p>
                      </div>
                    )}
                  {userData && userData.role === AccountType.CLIENT && (
                    <>
                      <div
                        className="submenu-link-container"
                        onClick={(e) => transitionTo("/account/profile", e)}
                      >
                        <p className="submenu-link">Profile</p>
                      </div>
                      <div
                        className="submenu-link-container"
                        onClick={(e) => transitionTo("/account/shipping", e)}
                      >
                        <p className="submenu-link">Shipping</p>
                      </div>
                      <div
                        className="submenu-link-container"
                        onClick={(e) => transitionTo("/account/quotes", e)}
                      >
                        <p className="submenu-link">Quotes</p>
                      </div>
                      <div
                        className="submenu-link-container"
                        onClick={(e) => transitionTo("/account/orders", e)}
                      >
                        <p className="submenu-link">Orders</p>
                      </div>
                    </>
                  )}
                  <div
                    className="submenu-link-container"
                    onClick={() => signOut()}
                  >
                    <p className="submenu-link">Sign Out</p>
                  </div>
                </div>
              </>
            ) : (
              <button
                className="login-button"
                onClick={(e) => changeLoginOpen()}
              >
                log - in
              </button>
            )}
            <button
              className="order-button"
              onClick={(e) =>
                transitionTo(
                  `${
                    signedIn && firebase.auth().currentUser
                      ? "/order/documents"
                      : "/order/contact"
                  }`,
                  e
                )
              }
            >
              Order
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewNavbar;
