import React from "react";

import { ReactComponent as ICON1 } from "../../assets/icons/TranslateUsIcon1.svg";
import { ReactComponent as ICON2 } from "../../assets/icons/TranslateUsIcon2.svg";
import { ReactComponent as ICON3 } from "../../assets/icons/TranslateUsIcon3.svg";
import { ReactComponent as ICON4 } from "../../assets/icons/TranslateUsIcon4.svg";

import "./TranslateUsSection.css"

const TranslateUsSection = () => {
  const CardsDetails = [
    {
      icon: <ICON1 />,
      title: "ATA Corporate Member",
      description:
        "As an ATA Corporate Member (#273062), we adhere to the highest standards of quality and professionalism",
    },
    {
      icon: <ICON2 />,
      title: "100% USCIS Acceptance",
      description:
        "Guaranteed acceptance by USCIS, courts, and other official institutions.",
    },
    {
      icon: <ICON3 />,
      title: "Trusted by 350+ Law Firms",
      description:
        "Preferred by over 350 law firms for legal and immigration translations.",
    },
    {
      icon: <ICON4 />,
      title: "Same-Day Delivery Available",
      description:
        "Need it fast? Our same-day delivery ensures you meet urgent deadlines with top-notch quality every time.",
    },
  ];
  return (
    <div className="section">
    <h2 className="section-title">Why translate with us?</h2>
  
    <div className="card-grid">
      {CardsDetails.map((card, index) => (
        <div key={index} className="card">
          <div className="card-icon">{card.icon}</div>
          <h1 className="card-title">{card.title}</h1>
          <p className="card-description">{card.description}</p>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default TranslateUsSection;
