import React, { useState, useEffect, useContext } from "react";
import "./LandingPage.css";
import { ReactComponent as ICON2 } from "../../assets/icons/HeroIcon1.svg";
import { ReactComponent as ICON4 } from "../../assets/icons/HeroIcon2.svg";
import { ReactComponent as ICON5 } from "../../assets/icons/HeroIcon3.svg";
import { ReactComponent as ICON3 } from "../../assets/icons/HeroIcon4.svg";
import { ReactComponent as ICON1 } from "../../assets/icons/HeroIcon5.svg";
import { useScreenTransition } from "../../Hooks/useScreenTransition";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import "./Hero.css";
import { AuthContext } from "../../Context/AuthContext";

const IconCircle = ({ ICON, style, className }) => {
  return (
    <div className={`IconCircle ${className}`} style={style}>
      <span className="icon-wrapper">{ICON}</span>
    </div>
  );
};

const HeroSection = () => {
  const styles = [
    {
      animationDuration: "12s",
      animationName: "up-down-left",
    },
    {
      animationDuration: "10s",
      animationDelay: "3s",
      animationName: "scale-in-out",
    },
    {
      animationDuration: "10s",
      animationDelay: "0s",
      animationName: "fade-in-out",
    },
    {
      animationDuration: "10s",
      animationDelay: "0s",
      animationName: "fade-in-out",
    },
    {
      animationDuration: "12s",
      animationName: "up-down-right",
    },
  ];
  const { transitionTo } = useScreenTransition();
   const { signedIn} = useContext(AuthContext);

  return (
    <div className="section">
      <div className="icon-grid hidden-md">
        <div className="icon-item start">
          <IconCircle
            ICON={<ICON1 />}
            style={styles[0]}
            className="animated-circle"
          />
        </div>
        <div className="icon-item center">
          <IconCircle
            ICON={<ICON2 />}
            style={styles[1]}
            className="animated-circle"
          />
        </div>
        <div className="icon-item end">
          <IconCircle
            ICON={<ICON3 />}
            style={styles[2]}
            className="animated-circle"
          />
        </div>
      </div>

      <div className="text-section">
        <h1 className="main-heading">Online Certified Translations</h1>
        <h2 className="sub-heading">
          Get your certified translations delivered in under 24 hours for just
          $24.95/page
        </h2>
        <button
          onClick={(e) =>
            transitionTo(
              `${
                signedIn && firebase.auth().currentUser
                  ? "/order/documents"
                  : "/order/contact"
              }`,
              e
            )
          }
          className="order-button"
        >
          Order Now
        </button>
      </div>

      <div className="icon-grid hidden-md icon-grid-2">
        <div className="icon-item center">
          <IconCircle
            ICON={<ICON4 />}
            style={styles[3]}
            className="animated-circle"
          />
        </div>
        <div className="icon-item center margin-bottom">
          <IconCircle
            ICON={<ICON5 />}
            style={styles[4]}
            className="animated-circle"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
