import React from "react";

import { ReactComponent as BlueRightCircle } from "../../assets/icons/BlueRightCircle.svg";
import { ReactComponent as UAE } from "../../assets/countryFlags/UAE.svg";
import { ReactComponent as BRAZIL } from "../../assets/countryFlags/brazil.svg";
import { ReactComponent as CHINA } from "../../assets/countryFlags/china.svg";
import { ReactComponent as INDIA } from "../../assets/countryFlags/india.svg";
import { ReactComponent as ITALY } from "../../assets/countryFlags/italy.svg";
import { ReactComponent as JAPAN } from "../../assets/countryFlags/japan.svg";
import { ReactComponent as MEXICO } from "../../assets/countryFlags/mexico.svg";
import { ReactComponent as RUSSIA } from "../../assets/countryFlags/russia.svg";
import { ReactComponent as SOUTHKOREA } from "../../assets/countryFlags/south korea.svg";
import { ReactComponent as UKRAINE } from "../../assets/countryFlags/ukraine.svg";
import { useScreenTransition } from "../../Hooks/useScreenTransition";

import "./PopularDocumentSection.css";

const PopularDocumentSection = ({ windowSize }) => {
  const popularDocumentData = [
    {
      title: "Birth Certificates",
    },
    {
      title: "Passports",
    },
    {
      title: "Driver's Licenses",
    },
    {
      title: "Diplomas",
    },
    {
      title: "Transcripts",
    },
    {
      title: "Bank Statements",
    },
    {
      title: "Marriage Certificates",
    },
    {
      title: "Vaccination Records",
    },
    {
      title: "Apostille",
    },
    {
      title: "Doctor's Notes",
    },
  ];
  const { transitionTo } = useScreenTransition();

  const countriesData = [
    {
      flag: <MEXICO />,
      language: "Spanish",
    },
    {
      flag: <BRAZIL />,
      language: "Portuguese",
    },
    {
      flag: <ITALY />,
      language: "Italian",
    },
    {
      flag: <UKRAINE />,
      language: "Ukrainian",
    },
    {
      flag: <RUSSIA />,
      language: "Russian",
    },
    {
      flag: <UAE />,
      language: "Arabic",
    },
    {
      flag: <INDIA />,
      language: "Hindi",
    },
    {
      flag: <CHINA />,
      language: "Chinese",
    },
    {
      flag: <SOUTHKOREA />,
      language: "Korean",
    },
    {
      flag: <JAPAN />,
      language: "Japanese",
    },
  ];

  const rows = [
    countriesData.slice(0, 3),
    countriesData.slice(3, 7),
    countriesData.slice(7, 10),
  ];
  return (
    <div className="SectionMargin">
      <div className="section-header">
        <h2 className="section-title">Most Popular Documents</h2>
        <p className="section-subtitle">
          Our experienced translators handle a wide variety of documents with
          accuracy and professionalism. Here are some of the most requested
          types:
        </p>
      </div>

      <div className=" languages-container">
        {popularDocumentData.map((item, index) => (
          <div key={index} className=" language-item">
            <BlueRightCircle />
            <span>{item.title}</span>
          </div>
        ))}
      </div>

      {/* Popular Languages Section */}

      <div className=" popular-languages ">
        <div className="section-header">
          <h2 className="section-title">Popular Languages</h2>
          <p className="responsive-paragraph">
            We work with a team of highly qualified, full-time translators,
            ensuring consistent quality across every language we offer.
          </p>

          <button
            onClick={() => transitionTo("/languages")}
            className="orange-button"
          >
            See all languages
          </button>
        </div>
        <div className="space-y-8 desktop-screen-languages">
          {rows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className={` popular-languages-container  ${
                row.length === 3
                  ? "grid-container-md-3"
                  : " grid-container-md-4"
              }`}
            >
              {row.map((el, index) => (
                <div key={index} className=" popular-languages-item">
                  {el.flag}
                  <span className="language-span">{el.language}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="mobile-screen-languages">
          <div className="mobile-screen-languages-container grid grid-cols-2 gap-8">
            {countriesData.map((el, index) => (
              <div
                key={index}
                className=" popular-languages-item"
              >
                {el.flag}
                <span className="language-span">{el.language}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularDocumentSection;
