import React from "react";

import { ReactComponent as ICON1 } from "../../assets/icons/UseCaseIcon1.svg";
import { ReactComponent as ICON2 } from "../../assets/icons/UseCaseIcon2.svg";
import { ReactComponent as ICON3 } from "../../assets/icons/UseCaseIcon3.svg";
import "./UseCasesSection.css";

const UseCasesSection = () => {
  const useCasesData = [
    {
      title: "Inmigration USCIS Acceptance",
      description:
        "Required for visa applications, green cards, and other immigration processes to ensure compliance with USCIS standards.",
      icon: <ICON1 />,
    },
    {
      title: "Court and Contract Submissions",
      description:
        "Necessary for translating contracts, affidavits, and other legal documents for court cases or legal procedures.",
      icon: <ICON2 />,
    },
    {
      title: "Academic University Admissions",
      description:
        "Needed for transcripts, diplomas, and other academic records required by universities and educational institutions.",
      icon: <ICON3 />,
    },
  ];

  return (
    <div className="SectionMargin">
      <div className="section-header">
        <h2 className="section-title">Use Cases</h2>
        <p className=" section-subtitle">
          Certified translations are typically required for official uses such
          as submission to USCIS, universities, courts and governments.
        </p>
      </div>

      <div className="card-grid-con">
        {useCasesData.map((card, index) => (
          <div key={index} className="card">
            <div className="card-icon">{card.icon}</div>
            <h1 className="card-title">{card.title}</h1>
            <p className="card-description">{card.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UseCasesSection;
