import React from "react";

import "./LandingPage.css";

import { useScreenTransition } from "../../Hooks/useScreenTransition";
import { ArrowUp } from "react-feather";

import "./NewFooter.css";

const NewFooter = () => {
  const { transitionTo } = useScreenTransition();

  const FooterItems = [
    {
      title: "Contact",
      path: "/contact",
    },
    {
      title: "Review",
      path: "/reviews",
    },
    {
      title: "Free Quote",
      path: "/quote/details",
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className=" footer-Container SectionMargin">
      <div className="container footerReadyContainer ">
        {/* Logo Section */}
        <div className="mb-6 md:mb-0">
          <img
            src={require("../../assets/logoAlt.png")}
            alt="Logo eVerbary"
            className="logo"
            onClick={(e) => transitionTo("/", e)}
          />
        </div>

        {/* Footer Navigation */}
        <div className=" navigation-container flex flex-col md:flex-row items-center gap-4 md:gap-8 mb-6 md:mb-0">
          {FooterItems.map((item, index) => (
            <div key={index} className="relative-group">
              <div
                className="group-text"
                onClick={(e) => transitionTo(item.path, e)}
              >
                {item.title}
              </div>
              <div className="group-underline"></div>
            </div>
          ))}
        </div>

        {/* Scroll to Top Button */}
        <div className=" topButton-container">
          <span className="text-[#F2F2F2] text-center md:text-left text-base md:text-lg font-semibold top-text">
            Go to Top
          </span>
          <button className=" topButton" onClick={scrollToTop}>
            <ArrowUp className="text-blue" />
          </button>
        </div>
      </div>

      {/* Footer Bottom Text */}
      <p className=" copyRight ">© 2025 eVerbary. All rights reserved.</p>
    </div>
  );
};

export default NewFooter;
