import React, { useContext, useEffect, useState } from "react";

import { ChevronRight } from "react-feather";

import { Navbar } from "../Components/Navbar";
import { AltAnimatedButton } from "../Components/AltAnimatedButton";
import { ServicesDisplay } from "../Components/ServicesDisplay";
import { UseCasesDisplay } from "../Components/UseCasesDisplay";
import { DocumentsDisplay } from "../Components/DocumentsDisplay";
import { LanguagesDisplay } from "../Components/LanguagesDisplay";
import { Footer } from "../Components/Footer";

import { TransitionContext } from "../Context/TransitionContext";

import { useScreenTransition } from "../Hooks/useScreenTransition";

import "./Home.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AuthContext } from "../Context/AuthContext";
import TopBar from "../Components/TopBar";

import SignInForm from "./SignIn/SignInForm";
import CreateAccount from "./CreateAccount/CreateAccount";
import NewNavbar from "./LandingPage/NewNavbar";
import HeroSection from "./LandingPage/HeroSection";
import TranslateUsSection from "./LandingPage/TranslateUsSection";
import TranslationServiceSection from "./LandingPage/TranslationServiceSection";
import UseCasesSection from "./LandingPage/UseCasesSection";
import PopularDocumentSection from "./LandingPage/PopularDocumentSection";
import NewFooter from "./LandingPage/NewFooter";

const HomeScreen = ({ windowSize }) => {
  const { setColor, webP } = useContext(TransitionContext);

  const { transitionTo } = useScreenTransition();

  const { signedIn } = useContext(AuthContext);

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen] = useState(false);

  const handleLoginOpen = (newState) => {
    setIsModalOpen(newState);
  };

  const handleCreateAccountOpen = (newState) => {
    setIsCreateAccountOpen(newState);
  };

  return (
    <>
      <SignInForm
        open={isModalOpen}
        isLoginFormOpen={handleLoginOpen}
        isCreateAccountOpen={handleCreateAccountOpen}
      />
      <CreateAccount
        open={isCreateAccountOpen}
        isCreateAccountOpen={handleCreateAccountOpen}
        isLoginFormOpen={handleLoginOpen}
      />

      <TopBar />
      <div className="home-Container ">
        <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
        <HeroSection />
        <TranslateUsSection />
        <TranslationServiceSection />
        <UseCasesSection />
        <PopularDocumentSection />
      </div>
        <NewFooter />
      {/* <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
      
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="heading">Online Translation Services</h1>

          <h2 className="subheading">Get your documents translated in 24 hours for just $24.95 per page.</h2>
          <AltAnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.6) : (windowSize.orientation === 'portrait' ? 6 : 2.2)} />
          <div className="extraInfoContainer">
            <div className="extraInfoTextContainer" onClick={(e) => transitionTo('/business',e)}>
              <p className="extraInfoText">Are you a business? See benefits.</p>
              <ChevronRight className="extraInfoButton" />
            </div>
            <div className="extraInfoUnderline" />
          </div>
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'home.webp' : 'home.png'}`)} alt="People sharing a document" className="headerImg" /> : null}
      </div>
      <div className="certificationContainer">
        <div className="certificationItemContainer">
          <img src={require('../assets/ata-logo.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">ATA Corporate Member #273062</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-3.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">100% guaranteed acceptance by USCIS</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-1.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Trusted by +350 law firms</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-2.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Same-day delivery available</p>
        </div>
      </div>
      <ServicesDisplay windowSize={windowSize} />
      <UseCasesDisplay />
      <DocumentsDisplay />
      <LanguagesDisplay />
      <Footer windowSize={windowSize} />
    </div> */}
    </>
  );
};

export default HomeScreen;
